<template>
	<div class="competitionBox">
		<div class="competitionHeader">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item v-for="(item,index) in breadList" :key="index">
					<a :href="item.cate_url" target="_blank">{{ item.name }}</a>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="midBox">
			<div class="contentBox">
				<div class="title">{{detailData.title}}</div>
				
				<div class="detailsBox">
					<div class="detailsBoxTitle">
						01 大赛进程
						<span class="decorateA"></span>
						<span class="decorateB"></span>
					</div>
					<div class="midDetail progress">
						<div class="progressItem">
							<div class="progressTitle">征集期</div>
							<div class="progressTiem">
								<div>{{detailData.levy_start_time | formDate("yyyy年MM月dd日")}}</div>
								<div>{{detailData.levy_end_time | formDate("yyyy年MM月dd日")}}</div>
							</div>
							<div class="bottom">
								<div class="up">solicitation period</div>
								<img src="../../assets/image/competition/arrows.png" alt="">
							</div>
						</div>
						<div class="progressItem">
							<div class="progressTitle">评选期</div>
							<div class="progressTiem">
								<div>{{detailData.review_start_time | formDate("yyyy年MM月dd日")}}</div>
								<div>{{detailData.review_end_time | formDate("yyyy年MM月dd日")}}</div>
							</div>
							<div class="bottom">
								<div class="up">SELECTION period</div>
								<img src="../../assets/image/competition/arrows.png" alt="">
							</div>
						</div>
						<div class="progressItem">
							<div class="progressTitle">公示期</div>
							<div class="progressTiem">
								<div v-if="detailData.exhibit_time">{{detailData.exhibit_time | formDate("yyyy年MM月dd日")}}</div>
								<div v-else>——</div>
							</div>
							<div class="bottom">
								<div class="up">publicity period</div>
								<img src="../../assets/image/competition/arrows.png" alt="">
							</div>
						</div>
					</div>
					
					<div v-for="(item, index) in detailData.detail" :key="index">
						<!-- 小标题 -->
						<div class="detailsBoxTitle">
							0{{index+2}} {{item.title}}
							<span class="decorateA"></span>
							<span class="decorateB"></span>
						</div>
						<!-- 详情 -->
						<div class="midDetail" v-html="item.content"></div>
					</div>
				</div>
				
				<div class="accessory" v-if="detailData.attachment&&detailData.attachment.length">
					<div class="accessoryName">附件：</div>
					<div class="accessoryList" v-for="(item, index) in detailData.attachment" :key="index" @click="jump(item.src)">
						
						<!-- <a :href="item.src" target="_blank" rel="noopener noreferrer"> -->
							点击下载：<span>{{item.title}}</span>
						<!-- </a> -->
					</div>
				</div>
			</div>
			<div class="rightBox">
				<div class="xiding">
					<advertising :sideAD="sideAD"></advertising>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import advertising from "./componts/advertising.vue";
import {
	DesignCompetitionGetDetail,
	getBanner,
	cloudLibrarygetCateNav
} from '@/Api'
import { mapGetters } from "vuex";
import {
	getToken
} from "@/utils/auth";
export default {
  name: 'CompetitionDetail',
  data() {
    return {
			breadList: [],
			sideAD: [],
			detailData: {
				title: "",	// 大赛标题
				cover: "",	// 大赛封面
				levy_start_time: "",	// 征集开始时间
				levy_end_time: "",	// 征集结束时间
				review_start_time: "",	// 评审开始时间
				review_end_time: "",	// 评审结束时间
				exhibit_time: "",	// 公式时间
				attachment: [],	// 附件数组
				detail: [],	// 大赛详情
			}
    }
  },
  components: {
		advertising,
  },
	computed: {
		...mapGetters([ "isLogin"]),
	},
  created() {
		const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
		if (query.detailId) {
			this.id = query.detailId;
			this.initData()
		}
  },
  methods: {
    initData() {
			DesignCompetitionGetDetail({id: this.id, token: getToken()}).then((res)=>{
				let detailData = res.data
				detailData.levy_start_time = detailData.levy_start_time*1000
				detailData.levy_end_time = detailData.levy_end_time*1000
				detailData.review_start_time = detailData.review_start_time*1000
				detailData.review_end_time = detailData.review_end_time*1000
				detailData.exhibit_time = detailData.exhibit_time*1000
				console.log(detailData, '53');
				this.detailData = detailData
			})
			getBanner({position: 2193, cate: 2196}).then((res) => {
				this.sideAD = res.data;
			})
			cloudLibrarygetCateNav(this.id).then((res) => {
				this.breadList = res.data;
			})
		},
		
		jump(url) {
      //先判断是否登录
      if (!this.isLogin) {
        this.$login();
        return;
      }
			if(url) {
				const downloadUrl = this.$ROOT_DIR + "/web/DesignCompetition/downAttachment?url=" + url
				let link = document.createElement("a");
				link.style.display = "none";
				link.href = downloadUrl;
				document.body.appendChild(link);
				link.click();
			}
		}
  },
  mounted() {
  },
  beforeDestroy() {
  }
}
</script>
<style scoped>
.xiding {
  position: sticky;
  top: 0.84rem;
}
	.competitionBox {
		position: relative;
		background: rgba(247, 249, 250, 1)
	}
	.competitionHeader {
		width: 100%;
		padding: 0.24rem 2.8rem;
	}
	.midBox {
		width: 100%;
		padding: 0.1rem 2.8rem;
		display: flex;
		justify-content: space-between;
	}
	.contentBox {
		width: calc(100% - 4rem);
		background-color: #fff;
		border-radius: 0.32rem;
		padding: 0.4rem 0.62rem;
	}
	.rightBox {
		width: 4rem;
		padding-left: 0.4rem;
	}
	.title {
		width: 100%;
		margin-bottom: 0.34rem;
		text-align: center;
		font-size: 0.28rem;
		font-weight: bold;
		color: rgba(30, 32, 35, 1);
	}
	.detailsBoxTitle {
		position: relative;
		width: 100%;
		height: 0.52rem;
		border-radius: 0.16rem;
		background: rgba(255, 233, 191, 1);
		text-align: center;
		line-height: 0.52rem;
		font-size: 0.24rem;
		font-weight: 700;
		color: rgba(255, 140, 26, 1);
	}
	.detailsBoxTitle::after{
		content: "design competition";
		position: absolute;
		top: 0.06rem;
		left: 0.3rem;
		width: 0.89rem;
		height: 0.4rem;
		text-align: left;
		font-size: 0.14rem;
		font-weight: 700;
		line-height: 0.2rem;
		color: rgba(255, 141, 26, 1);
		text-transform: uppercase;
	}
	.detailsBoxTitle::before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 0.52rem;
		border: 1px solid rgba(255, 141, 26, 1);
	}
	.decorateA::before {
		content: "";
		position: absolute;
		top: -0.02rem;
		left: -0.02rem;
		width: 0.06rem;
		height: 0.06rem;
		background: rgba(255, 255, 255, 1);
		border: 0.01rem solid rgba(255, 141, 26, 1);
	}
	.decorateA::after {
		content: "";
		position: absolute;
		top: -0.02rem;
		right: -0.02rem;
		width: 0.06rem;
		height: 0.06rem;
		background: rgba(255, 255, 255, 1);
		border: 0.01rem solid rgba(255, 141, 26, 1);
	}
	.decorateB::before {
		content: "";
		position: absolute;
		bottom: -0.02rem;
		left: -0.02rem;
		width: 0.06rem;
		height: 0.06rem;
		background: rgba(255, 255, 255, 1);
		border: 0.01rem solid rgba(255, 141, 26, 1);
	}
	.decorateB::after {
		content: "";
		position: absolute;
		bottom: -0.02rem;
		right: -0.02rem;
		width: 0.06rem;
		height: 0.06rem;
		background: rgba(255, 255, 255, 1);
		border: 0.01rem solid rgba(255, 141, 26, 1);
	}
	.midDetail {
		margin: 0.26rem 0;
		overflow-wrap: break-word;
	}
	.midDetail >>> img {
		max-width: 100%;
	}
	.midDetail >>> table {
		width: 100% !important;
		border:1px solid #DDD;
	}
	.midDetail >>> td,th{ border:1px solid #DDD; }  
	.progress {
		display: flex;
		justify-content: space-between;
		
	}
	.progressItem {
		width: 2.4rem;
		height: 2.4rem;
		border-radius: 0.4rem;
		background: rgba(255, 255, 255, 1);
		border: 10px solid rgba(255, 140, 26, 1);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		overflow: hidden;
	}
	.progressTitle {
		width: 100%;
		height: 0.52rem;
		background: rgba(73, 223, 112, 1);
		line-height: 0.52rem;
		text-align: center;
		font-size: 0.22rem;
		font-weight: 700;
		color: rgba(255, 255, 255, 1);
	}
	.progressTiem {
		flex: 1;
		padding: 0.16rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.progressTiem div {
		width: 100%;
		height: 0.32rem;
		line-height: 0.32rem;
		border-radius: 0.04rem;
		background: rgba(255, 233, 191, 1);
		font-size: 0.16rem;
		font-weight: 500;
		letter-spacing: 0.02rem;
		color: rgba(255, 140, 26, 1);
		text-align: center;
	}
	.progressItem .bottom{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.1rem 0.2rem 0.2rem;
	}
	.bottom .up{
		width: 0.88rem;
		text-transform: uppercase;
		font-size: 0.12rem;
		font-weight: 700;
		line-height: 0.16rem;
		color: rgba(255, 141, 26, 1);
		text-align: left;
		vertical-align: top;
	}
	.progressItem .bottom img{
		width: 0.26rem;
		height: 0.26rem;
		transform: rotate(90deg);
	}
	.detailsBox {
		padding-bottom: 0.14rem;
		margin-bottom: 0.3rem;
		border-bottom: 1px dashed rgba(128, 128, 128, 1);
	}
	.accessory {
		
	}
	.accessoryName {
		padding-bottom: 0.1rem;
		font-size: 0.18rem;
		font-weight: bold;
		color: rgba(20, 25, 31, 1);
	}
	.accessoryList {
		padding: 0.08rem 0;
		font-size: 0.16rem;
		font-weight: 400;
	}
	.accessoryList span{
		cursor: pointer;
		color: #3299ff;
	}
	.accessoryList span:hover {
		color: #3299ff;
	}
	
</style>
